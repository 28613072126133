import React from 'react';
import { Container, Box, Typography } from '@mui/material';
import Header from '../components/Header';
import aboutImage from '../assets/cooking.png';

export default function AboutPage() {

  return (
    <div>
      <Header />
      <Container maxWidth="lg" sx={{ mt: 10 }}>
        <Box sx={{ display: 'flex', flexDirection: { xs: 'column', md: 'row' }, alignItems: 'center' }}>
          <Box component="img" src={aboutImage} alt="About our service" sx={{ width: { xs: '100%', md: '45%' }, borderRadius: '8px' }} />
          <Box sx={{ ml: { xs: 0, md: 3 }, mt: { xs: 3, md: 0 }, textAlign: { xs: 'center', md: 'left' } }}>
            <Typography variant="h4" component="h1">
              料理のおすすめサービス
            </Typography>
            <Typography variant="body1" component="p" sx={{ mb: 2 }}>
              私たちのサービスは、AIを活用して主婦の皆さんが冷蔵庫の残り物から今日の夕食や晩御飯を簡単に決めるお手伝いをします。忙しい日々の中で、何を作るか悩む時間を減らし、家族みんなが喜ぶ美味しい料理を提案します。
            </Typography>
            <Typography variant="body1" component="p" sx={{ mb: 2 }}>
              冷蔵庫にある具材を入力するだけで、AIが最適なレシピを提案します。主婦の皆さんにとって、毎日の料理がもっと楽しく、もっと簡単になることを目指しています。
            </Typography>
            <Typography variant="body1" component="p" sx={{ mb: 2 }}>
              今日の夕食や晩御飯に何を作るか迷ったときは、ぜひ私たちのサービスをお試しください。AIがあなたの冷蔵庫の残り物から最適なレシピを提案します。
            </Typography>

            <Typography variant="body1" component="p" sx={{ mb: 2 }}>
            Our service uses AI to help housewives easily decide on today's dinner or supper from the leftovers in the refrigerator. It reduces the time spent worrying about what to cook in busy daily life and suggests delicious dishes that the whole family will enjoy.
            </Typography>
            <Typography variant="body1" component="p" sx={{ mb: 2 }}>
            Just enter the ingredients in your refrigerator, and AI will suggest the best recipes. We aim to make daily cooking more enjoyable and easier for housewives.
            </Typography>
            <Typography variant="body1" component="p" sx={{ mb: 2 }}>
            When you are unsure about what to cook for today's dinner or supper, please try our service. AI will suggest the best recipes from the leftovers in your refrigerator.
            </Typography>

          </Box>
        </Box>
      </Container>
    </div>
  );
}
