import React from 'react';
import { Container } from '@mui/material';
import Header from '../components/Header';
import HeroSection from '../components/HeroSection';
import CustomTabs from '../components/CustomTabs';
// import { AdmaxSwitch } from '../components/AdmaxSwitch';

export default function HomePage() {
  return (
    <>
      <Header />
      <Container maxWidth="lg" sx={{ mx: '5%' }}>
        <HeroSection />
        <h2 style={{textAlign: "center"}}>
          <strong>今日の料理を決めよう</strong>
        </h2>
        <h4 style={{textAlign: "center"}}>
          <strong>Let's decide on today's dish</strong>
        </h4>
        <h4 style={{textAlign: "center"}}>
          <strong>AIに聞いて残りの具材や今日作る料理に悩んだら気軽に聞こう</strong>
        </h4>
        <h6 style={{textAlign: "center"}}>
          <strong>Feel free to ask AI if you are worried about the remaining ingredients or what dish to make today.</strong>
        </h6>
        <CustomTabs />
        {/* <AdmaxSwitch id="355615d70fd1c87cfa018e9d9d45af8c"/> */}
      </Container>
    </>
  );
}
