import React from 'react';
import { Box, Typography } from '@mui/material';
import cookingImage from '../assets/image.png';
import { colors } from '../utils/theme';


export default function HeroSection() {
  return (
    <Box
      sx={{
        position: 'relative',
        height: '300px',
        backgroundImage: `url(${cookingImage})`,
        backgroundSize: 'cover',
        width: '85vw',
        bgcolor: colors.mainColor
      }}
    >
      <Typography
        variant="h4"
        sx={{
          position: 'absolute',
          bottom: '20px',
          left: '20px',
          color: 'white',
          fontFamily: 'DM Serif Text', // フォントを変更
        }}
      >
        Recipe Magic
      </Typography>
    </Box>
  );
}
