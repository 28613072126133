import React from 'react';
import { AppBar, Toolbar, Box, Typography, IconButton, Menu, MenuItem } from '@mui/material';
import { colors, theme } from '../utils/theme';
import lamp from '../assets/lamp.png';
import MenuIcon from '@mui/icons-material/Menu';
import { useLocation } from 'react-router-dom';

export default function Header() {
  const location = useLocation();
  const [anchorEl, setAnchorEl] = React.useState(null);
  const isMenuOpen = Boolean(anchorEl);

  const handleMenuOpen = (event: any) => {
    setAnchorEl(event.currentTarget);
  };

  const handleMenuClose = () => {
    setAnchorEl(null);
  };

  const getButtonStyle = (pathes: string[]) => ({
    fontSize: pathes.includes(location.pathname) ? '1.1rem' : '1rem',
    fontWeight: pathes.includes(location.pathname) ? 'bold' : 'normal',
    margin: '0 10px',
    cursor: 'pointer',
    color: pathes.includes(location.pathname) ? colors.subColor : 'black',
  });

  return (
    <AppBar sx={{
      bgcolor: 'white',
      zIndex: theme.zIndex.drawer + 1,
      boxShadow: 'none', // 影をなくす
    }}
    >
      <Toolbar>
        <img src={lamp} alt='logo' style={{ height: 30, marginRight: 5 }} />
        <Typography variant='h5' color={"black"}>
          Recipe Magic
        </Typography>
        <Box sx={{ flexGrow: 1 }} />
        <Box sx={{ display: { xs: 'none', md: 'flex' }, justifyContent: 'space-between', width: '20%' }}>
          <Typography
            component="span"
            sx={getButtonStyle(['/', '/home'])}
            onClick={() => window.location.pathname = '/home'}
          >
            HOME
          </Typography>
          <Typography
            component="span"
            sx={getButtonStyle(['/about'])}
            onClick={() => window.location.pathname = '/about'}
          >
            ABOUT
          </Typography>
        </Box>
        <Box sx={{ display: { xs: 'flex', md: 'none' } }}>
          <IconButton edge="start" aria-label="menu" onClick={handleMenuOpen}>
            <MenuIcon />
          </IconButton>
          <Menu
            anchorEl={anchorEl}
            open={isMenuOpen}
            onClose={handleMenuClose}
          >
            <MenuItem
              onClick={() => { handleMenuClose(); window.location.pathname = '/'; }}
              sx={getButtonStyle(['/', '/home'])}
            >
              HOME
            </MenuItem>
            <MenuItem
              onClick={() => { handleMenuClose(); window.location.pathname = '/about'; }}
              sx={getButtonStyle(['/about'])}
            >
              ABOUT
            </MenuItem>
          </Menu>
        </Box>
      </Toolbar>
    </AppBar>
  );
};

