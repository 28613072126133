import React, { useState } from 'react';
import { Box, Tabs, Tab, Typography, TextField, Button, CircularProgress } from '@mui/material';
import axios from 'axios';
import ReactMarkdown from 'react-markdown';
import { colors } from '../utils/theme';
import '../styles/CustomTabs.css';
import { neon } from '@neondatabase/serverless';

// const LIMIT_COUNT = 10

interface TabPanelProps {
  children?: React.ReactNode;
  index: number;
  value: number;
}

function TabPanel(props: TabPanelProps) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Typography component="div">{children}</Typography>
        </Box>
      )}
    </div>
  );
}

function a11yProps(index: number) {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`,
  };
}

const CustomTabs = () => {
  const [value, setValue] = useState(0);
  const [easyModeInput, setEasyModeInput] = useState<string>('');
  const [response, setResponse] = useState<string>('');
  const [loading, setLoading] = useState<boolean>(false);
  const [modelIndex, setModelIndex] = useState<number>(0);
  // const [usageCount, setUsageCount] = useState<number>(0);
  // const [isLimitReached, setIsLimitReached] = useState<boolean>(false);


  // useEffect(() => {
  //   const today = new Date().toISOString().split('T')[0];
  //   const storedDate = localStorage.getItem('usageDate');
  //   const storedCount = localStorage.getItem('usageCount');

  //   if (storedDate === today && storedCount) {
  //     setUsageCount(parseInt(storedCount, 10));
  //     if (parseInt(storedCount, 10) >= LIMIT_COUNT) {
  //       setIsLimitReached(true);
  //     }
  //   } else {
  //     localStorage.setItem('usageDate', today);
  //     localStorage.setItem('usageCount', '0');
  //   }
  // }, []);

  const models = [
    'gemini-1.5-flash',
    'gemini-1.5-pro',
    'gemini-1.5-lite',
    'gemini-1.5-basic',
    'gemini-1.0-pro',
    'gemini-1.0-lite',
    'gemini-1.0-basic'
  ];

  const handleChange = (event: React.SyntheticEvent, newValue: number) => {
    setValue(newValue);
  };

  const handleEasyModeSubmit = async () => {
    setLoading(true);
    let botMessageContent = '';

    // Neon DBのクライアントを設定
    const sql = neon(process.env.REACT_APP_NEON_DB_URL || "");

    try {
      await sql`INSERT INTO prompts (prompt) VALUES (${easyModeInput})`;
    } catch (error) {
      console.error('DB error:', error);
    }

    while (modelIndex < models.length && botMessageContent === '') {
      try {
        const res = await axios.post(
          `https://generativelanguage.googleapis.com/v1beta/models/${models[modelIndex]}:generateContent?key=${process.env.REACT_APP_GOOGLE_API_KEY}`,
          {
            contents: [
              {
                parts: [
                  {
                    text: easyModeInput
                  }
                ],
                role: "user"
              }
            ],
            systemInstruction: {
              parts: [
                {
                  text: `- ルール -\n 1.ReactMarkdownの形式で返却してください。\n2.入力言語にあわせて訳してください。(日本語なら日本語、英語なら英語)\n- フォーマット -\n料理名：\n【料理名】(bold)\n具材：\n具材の一覧と量を箇条書きで具材名(量:100gなど)\n料理の手順：\n料理の手順(時間や火力など具体的な数値も含めて)を示してほしいです。`
                }
              ],
              role: "model"
            }
          },
          {
            headers: {
              'Content-Type': 'application/json',
            }
          }
        );

        const botResponse = res.data;

        if (botResponse && botResponse.candidates && botResponse.candidates.length > 0) {
          const firstCandidate = botResponse.candidates[0].content;
          if (firstCandidate && firstCandidate.parts && firstCandidate.parts.length > 0) {
            botMessageContent = firstCandidate.parts.map((part: any) => part.text).join('\n');
          }
        }

        if (botMessageContent === '') {
          setModelIndex((prevIndex) => prevIndex + 1);
        }
      } catch (error) {
        console.error('API error:', error);
        setModelIndex((prevIndex) => prevIndex + 1);
      }
    }

    setResponse(botMessageContent);
    setLoading(false);
  };

  return (
    <Box sx={{ width: '100%' }}>
      <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
        <Tabs 
          value={value}
          onChange={handleChange}
          aria-label="basic tabs example"
          TabIndicatorProps={{ style: { backgroundColor: colors.subColor } }}
        >
          <Tab
            label="お手軽モード"
            {...a11yProps(0)}
            sx={{
              color: value === 0 ? colors.subColor : colors.sub2Color,
              '&.Mui-selected': { color: colors.subColor }
            }}
          />
          <Tab
            label="カスタムモード"
            {...a11yProps(1)}
            sx={{
              color: value === 1 ? colors.subColor : colors.sub2Color,
              '&.Mui-selected': { color: colors.subColor }
            }}
          />
        </Tabs>
      </Box>
      <TabPanel value={value} index={0}>
        <TextField
          placeholder={`豚肉とトマトが冷蔵庫に余ってるのそれをつかって料理を考えて\nI have some leftover pork and tomatoes in the fridge, so I'm thinking of cooking something to use them.`}
          multiline
          rows={4}
          variant="outlined"
          fullWidth
          value={easyModeInput}
          onChange={(e) => setEasyModeInput(e.target.value)}
          sx={{ mb: 2, '& .MuiOutlinedInput-root': { '&.Mui-focused fieldset': { borderColor: colors.subColor } } }}
        />
        <Button
          variant="contained"
          onClick={handleEasyModeSubmit}
          disabled={loading}
          sx={{ 
            backgroundColor: colors.subColor,
            '&:hover': { backgroundColor: colors.sub2Color },
            borderRadius: "20px"
          }}
        >
          {loading ? <CircularProgress size={24} /> : '質問する'}
        </Button>
        {response && (
          <Box sx={{ mt: 2 }}>
            <ReactMarkdown className="markdown-body">{response}</ReactMarkdown>
          </Box>
        )}
      </TabPanel>
      <TabPanel value={value} index={1}>
        開発中です。
      </TabPanel>
    </Box>
  );
};

export default CustomTabs;
