import styled from '@emotion/styled';
import { Typography } from '@mui/material';
import { createTheme } from '@mui/material/styles';

// テーマの作成
export const theme = createTheme({
  typography: {
    fontFamily: [
      'DM Serif Text',
      'Noto Sans JP',
      'sans-serif'
    ].join(','),
  },
});

export const colors = {
  mainColor: '#FBF9F7',
  subColor: "#A68263",
  sub2Color: "#FF4500",
  warmGradient: 'linear-gradient(45deg, #FF4500, #FF8C00, #FFA500)',
};

export const GradientText = styled(Typography)({
  background: colors.warmGradient,
  WebkitBackgroundClip: "text",
  WebkitTextFillColor: "transparent",
  fontWeight: "bold"
});
