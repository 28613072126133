import React from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import { ThemeProvider } from '@mui/material/styles';
import CssBaseline from '@mui/material/CssBaseline';
import { theme } from './utils/theme';
import './App.css';
import { 
  HomePage,
  AboutPage
} from './pages';
import ReactGA from "react-ga4";

function App() {
  ReactGA.initialize(process.env.REACT_APP_GA_TAG || "");
  ReactGA.send({ hitType: "pageview", page: "/home" });
  ReactGA.send({ hitType: "pageview", page: "/about" });
  return (
    <ThemeProvider theme={theme}>
      <CssBaseline />
      <Router>
        <Routes>
          <Route path='/home' element={<HomePage />} />
          <Route path='/about' element={<AboutPage />} />
          <Route path='*' element={<HomePage />} />
        </Routes>
      </Router>
    </ThemeProvider>
  );
}

export default App;
